import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import renderHtml from 'react-render-html'
import Layout from '../../components/Layout'

import '../../components/Narration.css'
import FooterSection from '../../components/FooterSection'

const NarrationPage = ({ data }) => {
  const { html } = data.markdownRemark
  console.log(data)

  return (
    <Layout language="ja">
      <div className="narration">{renderHtml(html)}</div>
      <FooterSection />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/ja/narration" } }) {
      html
      frontmatter {
        path
      }
    }
  }
`

export default NarrationPage
